import { APIv4 } from 'src/libs/axios';

export const addMenuPromotions = ({
  menuId,
  ids,
}: {
  menuId: number;
  ids: number[];
}): Promise<void> =>
  APIv4.post(`/catalogs/${menuId}/promotions`, ids).then(
    (response) => response.data
  );
