import { APIv4 } from 'src/libs/axios';

export const deleteMenuPromotion = ({
  menuId,
  id,
}: {
  menuId: number;
  id: number;
}): Promise<void> =>
  APIv4.delete(`/catalogs/${menuId}/promotions/${id}`).then(
    (response) => response.data
  );
