import { APIv4 as API } from 'src/libs/axios';

type MenuShortPromotionDTO = {
  id: number;
  name: string | null;
  inMenu: boolean;
  isActive: boolean;
};

export type MenuShortPromotion = {
  id: number;
  name: string;
  inMenu: boolean;
  isActive: boolean;
};

const mapMenuShortPromotion = (data: MenuShortPromotionDTO) => {
  return {
    ...data,
    name: data.name ?? '',
  };
};

export const fetchMenuShortPromotions = (): Promise<MenuShortPromotion[]> =>
  API.get(`/promotions`).then(({ data }) => data.map(mapMenuShortPromotion));
