import { APIv4 } from 'src/libs/axios';
import { BasePromotion } from '../../PromotionsPage/services';

export type MenuPromotion = BasePromotion & { position: number };

export const fetchMenuPromotions = (id: number): Promise<MenuPromotion[]> =>
  APIv4.get(`/catalogs/${id}/promotions`).then((response) =>
    response.data?.map((i: any, index: number) => ({
      ...i,
      position: index + 1,
    }))
  );
