import { APIv4 } from 'src/libs/axios';
import { alertError } from 'src/libs/toastify';
import { OptionFull } from 'src/components/MainPage/_shared/Options';
import { CommonViewProduct } from '../../catalog/MenuPage/services';
import { omit } from 'lodash';

export type BaseProductSet = {
  id?: number; // TODO проверить id
  count: number;
  isFree: boolean;
  displayName: string;
  isActive: boolean;
  products: {
    id: number;
    name: string;
    isActive: boolean;
    options: OptionFull[];
  }[];
};

export type ProductSet = Omit<BaseProductSet, 'products'> & {
  localId: string;
  products: CommonViewProduct[];
};

export type BasePromotion = {
  id: number;
  name: string;
  imageName: string;
  isActive: boolean;
  isPickupOnly: boolean;
  goByClick: boolean;
  position: number | null;
  price: number | null;
  discount: number | null;
  productSets: { id: number; count: number }[];
};

export type Promotion = Omit<BasePromotion, 'productSets'> & {
  description: string;
  sliderImageName?: string;
  imageNameForApp?: string;
  goByClick: boolean;
  availableOnBanner: boolean;
  worksOnHoliday: boolean;
  worksOnPreHoliday: boolean;
  startTime: string;
  startDate: string;
  endTime: string;
  endDate: string;
  promotionDays: number[];
  productSets: BaseProductSet[];
  catalogs: {
    id: number;
    name: string;
  }[];
};

export type PromotionValues = Omit<
  Promotion,
  'id' | 'productSets' | 'position'
> & {
  hasDiscount: boolean;
  productSets: ProductSet[];
};

export const fetchPromotions = () =>
  APIv4.get<BasePromotion[]>(`/promotions`)
    .then(({ data }) => data)
    .catch((err) => alertError(err, `Ошибка получения списка акций`));

export const fetchPromotion = ({ id }: { id: number }) =>
  APIv4.get<Promotion>(`/promotions/${id}`)
    .then(({ data }) => data)
    .catch((err) => alertError(err, `Ошибка получения акции`));

const adaptPromotion = (values: PromotionValues) => {
  const { productSets, hasDiscount, ...rest } = values;
  const catalogs = values.catalogs?.length
    ? values.catalogs.map(({ id }) => id)
    : null;

  return {
    ...rest,
    catalogs,
    productSets: !values.goByClick
      ? []
      : productSets.map(({ products, ...rest }) => ({
          ...omit(rest, 'localId', 'isActive'),
          products: products.flatMap(({ variants }) =>
            variants.map(({ id }) => id)
          ),
        })),
  };
};

export const addPromotion = (data: PromotionValues) =>
  APIv4.post(`/promotions`, adaptPromotion(data)).then(({ data }) =>
    Boolean(data)
  );

export const updatePromotion = (params: {
  id: number;
  data: PromotionValues;
}) =>
  APIv4.put(`/promotions/${params.id}`, adaptPromotion(params.data)).then(
    ({ data }) => Boolean(data)
  );

export const deletePromotion = ({ id }: { id: number }) =>
  APIv4.delete(`/promotions/${id}`).then(({ data }) => data);

export const blockPromotion = (params: { id: number; isActive: boolean }) =>
  APIv4.patch(`/promotions/${params.id}`, { isActive: params.isActive }).then(
    ({ data }) => data
  );
