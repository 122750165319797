import { getProductSets } from 'src/components/MainPage/_shared/ProductSets/utils';
import {
  CommonViewProduct,
  fetchCommonViewProducts,
} from 'src/pages/catalog/MenuPage/services';
import { fetchPromotion } from 'src/pages/promotions/PromotionsPage/services';
import { fetchMenus } from '../PromotionsMenuPage/services';

export const promotionLoader = async ({ params }: any) => {
  let products: CommonViewProduct[] = [];
  let catalogs = [];

  try {
    products = (await fetchCommonViewProducts()).sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    catalogs = await fetchMenus().then((data) =>
      data.sort((a, b) => a.id - b.id)
    );
  } catch {
    return null;
  }

  const id = Number(params.id);
  if (!products) return null;
  if (!id) return { data: null, catalogs, products };

  try {
    const data = await fetchPromotion({ id });
    if (!data) return null;

    // @ts-ignore
    data.productSets = getProductSets(products, data.productSets);

    return {
      data,
      catalogs,
      products,
    };
  } catch {
    return null;
  }
};
