import { APIv4 } from 'src/libs/axios';

export const swapMenuPromotions = ({
  menuId,
  ids,
}: {
  menuId: number;
  ids: [number, number];
}): Promise<void> =>
  APIv4.put(`/catalogs/${menuId}/promotions/position`, { ids }).then(
    (response) => response.data
  );
