import React, { lazy } from 'react';
import { redirect } from 'react-router-dom';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import ProtectedRoute from 'src/pages/utility/ProtectedRoute';
import { roleSet } from 'src/constants/role';
import type { AppRoute } from '../types';

const PromoCodesPage = lazy(() => import('src/pages/reports/PromoCodesPage'));
const SalesPage = lazy(() => import('src/pages/reports/SalesPage'));
const TicketsPage = lazy(() => import('src/pages/reports/TicketsPage'));
const DeliveryPage = lazy(() => import('src/pages/reports/DeliveryPage'));

export const reportsRoutes: AppRoute[] = [
  {
    path: 'reports',
    element: <ProtectedRoute roles={roleSet.adminToSuperAdmin} />,
    handle: {
      label: 'Отчёты',
      icon: <ViewListOutlinedIcon />,
      roles: roleSet.adminToSuperAdmin,
    },
    children: [
      {
        index: true,
        loader: async () => redirect('promocodes'),
      },
      {
        path: `promocodes`,
        element: <PromoCodesPage />,
        handle: {
          label: 'Отчёт по промокодам',
          roles: roleSet.adminToSuperAdmin,
        },
      },
      {
        path: `sales`,
        element: <SalesPage />,
        handle: {
          label: 'Отчёт по продажам',
          roles: roleSet.adminToSuperAdmin,
        },
      },
      {
        path: `delivery`,
        element: <DeliveryPage />,
        handle: {
          label: 'Отчёт по доставке',
          roles: roleSet.adminToSuperAdmin,
        },
      },
      {
        path: `tickets`,
        element: <TicketsPage />,
        handle: {
          label: 'Отчёт по тикетам',
          roles: roleSet.adminToSuperAdmin,
        },
      },
    ],
  },
];
